.formWrapper{
    display:flex;
    flex-direction:column;
    align-items:flex-start;
}
.editWrapper{
    display:flex;
    justify-content: center;
    padding: 60px 0px 0px;
}
.formInputFull{
    min-width:900px;
    font-size: 20px;
    font-family: 'Raleway', sans-serif
}
.formInputHalf{
    font-size: 20px;
    font-family: 'Raleway', sans-serif
}
.formBody{
    min-width: 900px;
    min-height: 600px;
    margin-bottom: 25px;
    margin-top: 5px;
}
label{
    margin: 10px 0px 10px 0px;
    font-weight: bold;

}
.checkboxWrapper{
    margin: 5px 0px;
}
.nameHeading{
    text-align: center;
    font-family: 'Kaushan Script', 'cursive';
}
textarea{
    font-family: 'Raleway', sans-serif;
    font-size: 20px;
}
.checkboxLabel{
    font-weight: normal;
    margin:0px;
}
button{
    margin-top: 20px;
    background-color: #FAFAFA;
    border:none;
    padding: 15px;
    font-size: 15px;
    font-weight: bold;
    font-family: 'Raleway', sans-serif;
    border-radius: 10px;
}
.markDownLink{
    color:#92F2F2;
}
.editOuter{
    display:flex;
    align-items: center;
    flex-direction: column;
}
.formBlockWrapper{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-right:10px;
}
.formHalfWrapper{
    display: grid;
    grid-template-columns: 1fr 1fr;
    width:100%;
    column-gap: 50px;
}
@media only screen and (max-width: 910px){
    .formInputFull{
        min-width:350px;
        font-size: 20px;
    }
    .formBody{
        min-width: 350px;
        min-height: 600px;
        margin-bottom: 25px;
        margin-top: 5px;
    }
    button{
        margin-bottom: 20px;
    }
    .formHalfWrapper{
        grid-template-columns: 1fr;

    }
}
