.sectionWrapper{
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content:center;
}
.nameHeading {
    font-family: 'Kaushan Script', cursive;
    text-align: center;
    padding-top:20px;
    margin-bottom:80px;
}
.pageWrapper{
    display:flex;
    flex-direction: column;
    align-items: center;

}
.newButton{
    background-color:white;
     padding:15px; 
     color:black; 
     border-radius:10px;
}
.editSectionHeader{
    display:flex;
    width:100%; 
    justify-content: space-between;
    align-items: center;
}