body{
    background-color: #1e1e1e;
    color: #FAFAFA;
    font-family: 'Raleway', sans-serif;
    font-weight:normal;
    letter-spacing: 20%;
}
.navStyle{
    background-color: #1e1e1e;
    padding:15px 50px ;
    display:flex;
    justify-content: space-between;

}
.navLink{
    color:white;
    text-decoration: none;
    margin-right:20px;
    font-family: 'Raleway', sans-serif;
    font-weight: 400;
    font-size: 22px;
}

#nameLink{
    font-weight:bold;
    font-family: 'Kaushan Script', cursive;
    font-weight: 400;
    font-size: 28px;
}
.navLinkWrapper{
    display:flex;
    align-items: center;
    justify-content:flex-end;
}
.sectionBlock{
    display:flex;
    align-items: center;
    justify-content: space-between;
    line-height: 62px;
    border-radius: 10px;
    padding: 18px;
}
.homeWrapper{
    padding: 40px 100px;
    max-width: 1200px;
    margin:auto;
}
.sectionBlockHeading{
    margin:0px;
    font-size: 35px;
    font-family: 'Raleway', sans-serif;
    color:black;
    font-weight:400;
}
.sectionBlockWrapper{
    margin-bottom:20px;
}
@media only screen and (max-width: 768px){
    .navStyle{
        background-color: #1e1e1e;
        padding:15px 15px ;
        display:flex;
        justify-content: space-between;
    
    }
    .sectionBlockHeading{
        font-size: 25px;
    }
    .homeWrapper{
        padding: 40px 20px;
        max-width: 1200px;
        margin:auto;
    }
    .sectionBlock{
        line-height: 32px;
        padding: 14px;
    }
}