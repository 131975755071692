.loginInput{
    border-radius:10px;
    border: 3px solid #FAFAFA;
    padding:10px;
    background-color:#1e1e1e;
    margin:0px 0px 20px 0px;
    width:300px;
    color: white;
    font-family: Raleway, sans-seriff;
}
.submitButton{
    padding:10px;
    font-size:15px;
    font-family:Raleway, sans-seriff;
    font-weight: bold;
}
.loginForm{
    display: flex;
    flex-direction:column;
    align-items:center;
}
.loginWrapper{
    display:flex;
    align-items:center;
    justify-content:center;
    height:100vh;
}
.michaelBranconier{
    font-family: Kaushan Script, cursive;
}