.blogPostWrapperInner{
    padding: 50px 20px;
    max-width:1200px;
    display: flex;
    flex-direction: column;

}
.blogPostWrapperOuter{
    display: flex;
    flex-direction: column;
    align-items: center;

}

.blogDetailDate{
    font-weight: 600;
    font-size:20px;
}
.blogDetailHeading{
    margin-bottom: 5px;
}
.blogDetailBody{
    margin-top:20px;
    line-height: 25px;
    font-size:16px;
}
.mediumButton{
    font-family: 'Raleway', sans-serif;
    font-weight: bold;
    border-radius: 10px;
    border: none;
    padding: 10px 10px;
    font-size:18px;
    margin-top:50px;
}
.divider{
    width:100%;
    height:1px;
    background-color: white;
    margin-top:80px;
}