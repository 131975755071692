/* Global styles */
.post {
    text-decoration: none;
    color: white;
}

.postDiv {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* Styles for public page */
.post .title,
.post h3 {
    color: white;
}

/* Styles for edit page */
.dataListWrapper {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
}

.postEdit {
    margin-bottom: 20px;
    width: 100%;
}

.postEdit .postDiv {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
}

.postEdit .postContent {
    flex-grow: 1;
    margin-right: 20px;
}

.postEdit .title {
    color: #333;
    margin: 0;
}

.postEdit h3 {
    color: #666;
    margin: 5px 0 0;
}

.actionButtons {
    display: flex;
    gap: 10px;
    flex-shrink: 0;
}

.actionIcon {
    cursor: pointer;
    color: #555;
    transition: color 0.3s ease;
}

.actionIcon:hover {
    color: #000;
}

/* Additional styles for edit page */
.postEdit a {
    text-decoration: none;
    color: inherit;
}

/* Ensure consistent width for all elements in edit view */
.editWrapper {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
}

.formWrapper {
    width: 100%;
}

.formInputFull {
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 15px;
}

.postEdit .postDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.postEdit .postDiv > div:first-child {
    flex-grow: 1;
    margin-right: 20px;
}