
.profilePhoto{
    width:250px;
    height:250px;
    object-fit:cover;
    border-radius: 50%;

}
.welcomeMessage{
    font-size: 28px;

}
.aboutText{
    font-size: 16px;
    max-width:800px;
    letter-spacing: 10%;

}
.aboutContentWrapper{
    display:flex;
    align-items: center;
    padding: 20px 10px; 
}
.aboutPhotoCol{
    width:30%;
    margin-right:30px;
}
.aboutTextCol{
    width:65%;
}
.aboutLink {
    color:white;
}

@media only screen and (max-width: 768px) {
    .aboutContentWrapper{
        flex-direction: column;
        text-align: center;
        justify-content: center;
    }
    .aboutTextCol{
        width:100%
    }
    .aboutPhotoCol{
        width:100%;
        margin-right:0px;
    }
    .profilePhoto{
        width:200px;
        height:200px;
    
    }
    .aboutContentWrapper{
        display:flex;
        align-items: center;
    }
}
