.expPosition{
    font-weight:bold;
    font-size:27px;
    margin-bottom:15px;

}
.expCompany{
    font-size:20px;
    color: #833232;
    margin-top:0px;
    margin-bottom:5px;
}

.expDetail{
    font-size:20px;
    color: #C0C0C0;
    margin:0px;

}
.expDescription{
    font-size:16px;
    line-height: 25px;
    max-width: 1200px;
}
.expDescriptionBullet{
    margin-bottom: 5px;
}
.expBlockWrapper{
    padding:0px 30px;
}
