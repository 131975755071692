.footerWrapper{
    display:flex;
    justify-content:center;
}
.footerLinkWrapper{
    width:100%;
    display:flex;
    justify-content:space-between;
    align-items: center;
    margin-top:100px;
    max-width: 600px;
}
.footerImg{
    width:75px;
}
@media only screen and (max-width: 768px){
    .footerLinkWrapper{
        width:90%;
        margin-top:30px;
    }
    .footerImg{
        width:50px;
    }
}